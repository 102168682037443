@font-face {
  font-family: "BPmono";
  src: url("./assets/fonts/bpmono.regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "BPmono";
  src: url("./assets/fonts/bpmono.bold.ttf") format("truetype");
  font-weight: 700;
}

body {
  background-color: #000000;
  color: #fff;
  /* font-family: "BPmono", sans-serif; */
}

.card-dark {
  background-color: #333;
  color: #eee;
}

.form-control {
  border-radius: 2px;
}

.form-label {
  margin-bottom: 0.25rem;
  font-size: 0.75em;
  font-weight: bold;
}

.form-control:disabled {
  background-color: #ccc;
}

.btn {
  border-radius: 2px;
}

.btn-row {
  display: flex;
}

.btn-row > *:not(:last-child) {
  margin-right: 0.25rem;
}

.list-horizontal li {
  display: inline-block;
}

.list-horizontal .list-divider {
  margin: 0 0.5rem;
  opacity: 0.5;
}

.note {
  font-size: 0.8em;
  opacity: 0.85;
  margin: 0;
  margin-top: 0.5rem;
}

/** *************************************** */
/** Contributors */
/** *************************************** */

.contributors li {
  margin-right: 0.5rem;
}

.contributors li:last-child {
  margin-right: 0;
}

.contributor-profile {
  border-radius: 10em;
  height: 30px;
  width: 30px;
  border: 1px solid #ffffff;
}

/** *************************************** */
/** Layout */
/** *************************************** */

.layout {
  font-family: "BPmono", sans-serif;
}

.layout-content {
  position: relative;
}

.layout-component {
  position: absolute;
}

/** *************************************** */
/** Elements */
/** *************************************** */

.element {
  position: relative;
  cursor: pointer;
}

.element-nested {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -6px;
}

.element-icon {
  display: block;
  width: 100%;
  height: 100%;
}

.element-label {
  position: absolute;
  bottom: -13px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
  background-color: #000;
  width: fit-content;
  padding: 0px;
  min-width: 100%;
  font-weight: 700;
  cursor: pointer;
}

.element-counter {
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  bottom: -10px;
  right: 0;
  cursor: pointer;
}

/** *************************************** */
/** Check Tracking */
/** *************************************** */

.check-tracker {
  padding: 0.5em;
  list-style: none;
  width: 300px;
}

.check-tracker .buttons {
  padding: 0 2px;
}

.check-tracker-locations {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  font-family: "BPmono", sans-serif;
}

.check-tracker-locations .item {
  padding: 2px;
}

.check-tracker-locations .item > button {
  width: 100%;
  aspect-ratio: 2/1;
}

.check-tracker-location {
  padding: 2px;
}

.check-tracker .check-list {
  padding: 0;
  padding-left: 2px;
  list-style: none;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  margin-bottom: 0;
}


.editor .card-body::-webkit-scrollbar,
.check-tracker .check-list::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
}

.editor .card-body::-webkit-scrollbar-thumb,
.check-tracker .check-list::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255, 0.5);
  border-radius: 0.5rem;
}

.check-tracker .check-list .check {
  line-height: 1;
}

.check-tracker .check-list .check button {
  font-size: 14px;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 2px 0;
}

.check-tracker .check-list .check button:disabled {
  opacity: 0.5;
  cursor: default;
}

.check-tracker .info {
  font-size: 0.75em;
  margin: 1rem 0;
}

/** *************************************** */
/** Editor */
/** *************************************** */

.editor {
  width: 350px;
  max-height: calc(100vh - 3rem);
}

.editor .card-body {
  overflow-y: auto;
}

.editor .uuid {
  font-size: 0.7em;
  opacity: 0.75;
  margin: 0.25rem 0;
}

.editor .component-list ul,
.editor .element-list ul {
  overflow-y: auto;
  /* max-height: 50vh; */
}

.editor .component-list ul button,
.editor .element-list ul button {
  width: 100%;
  margin-bottom: 0.25rem;
  text-align: left;
}

.editor .table-list {
  /* max-height: 200px !important; */
  margin-bottom: 0;
}

.editor .table-list li {
  cursor: pointer;
  background-color: #222;
  border-radius: 2px;
  padding: 0 0.25rem;
  margin-bottom: 0.25rem;
}
